import { useState } from "react"
import { schoolOrganizationOptions } from "../organizationOptions"

const defaultConfiguration = {
  subdomain: null,
  subdomainSchoolId: null,
  subdomainAudienceType: "ADULT",
  subdomainSamlProviderId: null,
  subdomainOpenIdConnectProviderId: null,
  isWhitelistedSubdomain: true,
}

/**
 * @description Function to get initial subdomain data including the subdomain, school id, audience type, saml provider id, openid connect provider id, and whether the subdomain is whitelisted
 * Whitelisting is necessary here because of wildcard subdomains and the need to redirect non-whitelisted subdomains to the main site
 * 
 * Whitelisted sites include the following:
 *  - Production deployment for www.wingspans.com
 *  - Production deployments where the subdomain matches the key in the organization options file
 *  - Localhost builds
 *  - Live Development builds where the domain is not wingspans.com 
 * 
 * Non-whitelisted sites include any other subdomains that are not in the organization options file
 *
 * @returns {Object} subdomainData - The subdomain data object
 * @returns {string} subdomainData.subdomain - The subdomain name from the URL
 * @returns {string} subdomainData.subdomainSchoolId - The school id associated with the subdomain
 * @returns {string} subdomainData.subdomainAudienceType - The audience type associated with the subdomain (ADULT or K-12)
 * @returns {string} subdomainData.subdomainSamlProviderId - The saml provider id associated with the subdomain (if applicable)
 * @returns {string} subdomainData.subdomainOpenIdConnectProviderId - The openid connect provider id associated with the subdomain (if applicable)
 * @returns {boolean} subdomainData.isWhitelistedSubdomain - Whether the subdomain is whitelisted, non whitelisted subdomains will redirect to the main site
 */ 
const getSubdomainData = () => {
  // catch for server side rendering
  if (typeof window === "undefined") {
    return defaultConfiguration
  }

  const subdomain = window.location.hostname.split(".")[0]
  const domain = window.location.hostname.split(".")[1]

  // For testing purposes, you can set a default organization for this value that matches the subdomain / key
  // const subdomain = 'www'
  // const domain = 'wingspans'

  // should never be the case
  if (!subdomain) {
    return defaultConfiguration
  }

  const matchedOrganization = schoolOrganizationOptions.find((org) => org.key === subdomain)

  if (subdomain !== "www" && domain === "wingspans" && !matchedOrganization) {
    defaultConfiguration.isWhitelistedSubdomain = false
  }

  if (!matchedOrganization) {
    console.warn("Subdomain not in list of options:", subdomain)
    return defaultConfiguration
  }

  // at this point we have a matched organization
  const { key, value, audience_type = "ADULT", saml_provider_id, openid_connect_provider_id } = matchedOrganization

  return {
    subdomain: key,
    subdomainSchoolId: value,
    subdomainAudienceType: audience_type === "ADULT" || audience_type === "K-12" ? audience_type : "ADULT",
    subdomainSamlProviderId: saml_provider_id,
    subdomainOpenIdConnectProviderId: openid_connect_provider_id,
    isWhitelistedSubdomain: defaultConfiguration.isWhitelistedSubdomain,
  }
}

/**
 * @description Custom hook to get subdomain data
 * @returns {Object} subdomainData
 * @returns {string} subdomainData.subdomain - The subdomain name from the URL
 * @returns {string} subdomainData.subdomainSchoolId - The school id associated with the subdomain
 * @returns {string} subdomainData.subdomainAudienceType - The audience type associated with the subdomain (ADULT or K-12)
 * @returns {string} subdomainData.subdomainSamlProviderId - The saml provider id associated with the subdomain (if applicable)
 * @returns {string} subdomainData.subdomainOpenIdConnectProviderId - The openid connect provider id associated with the subdomain (if applicable)
 * @returns {boolean} subdomainData.isWhitelistedSubdomain - Whether the subdomain is whitelisted, non whitelisted subdomains will redirect to the main site
 */
const useSubdomain = () => {
  const [subdomainData] = useState(() => getSubdomainData())
  return subdomainData
}

export default useSubdomain
