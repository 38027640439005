import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "../../components/Firebase"
import Link from "../../link"
import SEO from "../../components/seo"
import ReactPasswordStrength from "react-password-strength/dist/universal"
import { Alert } from "react-bootstrap"
import dayjs from "dayjs"
import { List } from "semantic-ui-react"
import userType, { schoolUserTypes, organizationUserTypes } from "../user/userType"
import { FormattedMessage, useIntl } from "react-intl"

const MyAccount = () => {
  const { firebase, user, loading, profile } = useContext(FirebaseContext)
  const [errorMessages, setErrorMessages] = useState([])
  const [messages, setMessages] = useState([])
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    city: "",
    state: "",
  })
  const [errors, setErrors] = useState({})
  const [pointEvents, setPointEvents] = useState(null)
  const misMatchErrorMessage = "Password and Password Confirm must match"
  const weakPasswordMessage = "Password is not strong enough"
  const { locale } = useIntl();

  useEffect(() => {
    if (profile) {
      setFormValues({
        ...formValues,
        firstName: profile.firstName ?? "",
        lastName: profile.lastName ?? "",
        email: profile.email ?? "",
        state: profile.state ?? "",
        city: profile.city ?? "",
      })
    }
  }, [profile]) /* eslint-disable-line */

  useEffect(() => {
    let isCancelled = false

    if (loading) {
      return
    }

    if (firebase) {
      try {
        const loadPointEvents = async () => {
          const res = await firebase.loadPointEvents()
          if (!isCancelled && res) {
            setPointEvents(res.sort((a, b) => b.eventDate - a.eventDate))
          }
        }
        loadPointEvents()
      } catch (e) {
        console.error("💣", e)
      }
    }

    return () => {
      isCancelled = true
    }
  }, [firebase, loading])

  function handleInputChange(e) {
    e.persist()
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))

    if (formValues.password && e.target.name === "confirmPassword") {
      const passwordError =
        e.target.value !== formValues.password ? misMatchErrorMessage : ""
      setErrors({
        ...errors,
        confirmPassword: passwordError,
      })
    }
  }

  function handlePasswordChange({ isValid, password }) {
    // if password is being changed, confirm should be wiped clear
    setFormValues({
      ...formValues,
      password: password,
      confirmPassword: "",
    })

    const prevErrors = errors

    if (!isValid && password.length > 0) {
      prevErrors.password = weakPasswordMessage
    } else {
      if (prevErrors.password) {
        delete prevErrors.password
      }
    }

    if (errors.confirmPassword) {
      delete errors.confirmPassword
    }

    setErrors(prevErrors)
  }

  function validates() {
    // password validation
    const validations = {}
    if (formValues.password) {
      if (formValues.password !== formValues.confirmPassword) {
        validations.confirmPassword = misMatchErrorMessage
      }
    }

    // carry over password validation
    if (errors.password === weakPasswordMessage) {
      validations.password = errors.password
    }

    // first and last name validation
    if (formValues.firstName.length < 2) {
      validations.firstName =
        "First name is required and must be at least 2 characters"
    }

    if (formValues.lastName.length < 2) {
      validations.lastName =
        "Last name is required and must be at least 2 characters"
    }

    setErrors(validations)
    return JSON.stringify(validations) === "{}"
  }

  async function handleSubmit(e) {
    e.preventDefault()

    setMessages([])

    if (!validates()) {
      setErrorMessages(["Please correct the problems above"])
    } else {
      setErrorMessages([])
      // set up firestore fields
      const profileData = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        city: formValues.city,
        state: formValues.state,
      }

      if (
        formValues.email !== user.email &&
        user.providerData[0].providerId === "password"
      ) {
        try {
          await firebase.updateUserEmail(formValues.email)
          setMessages([...messages, "Email address updated"])
        } catch (e) {
          setErrorMessages([
            ...errorMessages,
            e.code === "auth/requires-recent-login"
              ? "Updating email address or password is a sensitive operation. Please log in again before trying this request."
              : e.message,
          ])
          return
        }
      }

      if (formValues.password) {
        try {
          await firebase.updateUserPassword(formValues.password)
          setMessages([...messages, "Password updated"])
        } catch (e) {
          setErrorMessages([...errorMessages, e.message])
          return
        }
      }

      if (profile.membershipType === "individual") {
        // update password if appropriate
      }
      if (profile.membershipType === "student") {
      }

      // Profile update
      try {
        await firebase.updateProfile({ userId: user.uid, data: profileData })
        setMessages([...messages, "Account information has been updated"])
      } catch (e) {
        setErrorMessages([...errorMessages, e.message])
      }
    }
  }

  if (profile === null || !!loading) {
    return (
      <>
        <div
          style={{
            textAlign: "center",
            marginTop: "10em",
            marginBottom: "10em",
            fontSize: "20px",
          }}
        >
          <FormattedMessage
            id="account-loading"
            defaultMessage="Loading ..." />
        </div>
      </>
    )
  }

  const getMemberShipMessage = (memberType) => {
    if (schoolUserTypes.includes(memberType)) {
      return (<small>*<FormattedMessage
        id="account-membership-managed-by-school"
        defaultMessage="Your membership is managed by your school" /></small>)
    }
    if (organizationUserTypes.includes(memberType)) {
      return (<small>*<FormattedMessage
        id="account-membership-managed-by-organization"
        defaultMessage="Your membership is managed by your organization" /></small>)
    }
    return null
  }

  const isStripeUser = (memberType) => {
    if (userType.INDIVIDUAL !== memberType) {
      return false
    }
    return !!user?.membership?.stripeSubscriptionEnd
  }

  return (
    <section>
      <SEO title="My Account" />

      <section
        className="account account-header"
        style={{ backgroundColor: "#f6f6f6" }}
      >
        <div className="inner inner-account inner-account-header">
          <h1><FormattedMessage
            id="account-my-account"
            defaultMessage="My Account" /></h1>
        </div>
      </section>

      <section className="myaccount">
        <div className="inner inner-account inner-account-form">
          <form className="form form-account" onSubmit={handleSubmit}>
            <fieldset>
              <legend><FormattedMessage
                id="account-personal-details"
                defaultMessage="Personal details" /></legend>
              <div className="form-element">
                <label htmlFor="firstName">
                  <FormattedMessage
                    id="account-first-name"
                    defaultMessage="First Name" />: <span className="input-required">*</span>
                </label>
                <input
                  onChange={handleInputChange}
                  name="firstName"
                  type="text"
                  required
                  value={formValues.firstName}
                />
                <span style={{ color: "red" }}>{errors.firstName}</span>
              </div>
              <div className="form-element">
                <label htmlFor="lastName">
                  <FormattedMessage
                    id="account-last-name"
                    defaultMessage="Last Name" />: <span className="input-required">*</span>
                </label>
                <input
                  onChange={handleInputChange}
                  name="lastName"
                  type="text"
                  required
                  value={formValues.lastName}
                />
                <span style={{ color: "red" }}>{errors.lastName}</span>
              </div>

              <div className="form-element">
                <label htmlFor="email">
                  <FormattedMessage
                    id="account-email"
                    defaultMessage="Email" />: <span className="input-required">*</span>
                </label>
                {!!user.providerData && user.providerData[0].providerId !== "password" && (
                  <small>
                    <FormattedMessage
                      id="account-email-password-managed-by-provider"
                      defaultMessage="Your email and password is managed through your login provider" />
                  </small>
                )}
                <input
                  onChange={handleInputChange}
                  name="email"
                  type="text"
                  required
                  value={formValues.email}
                  disabled={user.providerData && user.providerData[0].providerId !== "password"}
                />
              </div>

              {!!user.providerData &&
                user.providerData[0].providerId === "password" && (
                  <>
                    <div className="form-element">
                      <label htmlFor="password">
                        <FormattedMessage
                          id="account-update-password"
                          defaultMessage="Update Password" />:{" "}
                        <span className="input-required">*</span>
                      </label>
                      <span
                        style={{
                          fontWeight: "normal",
                          color: "#565656",
                          display: "block",
                        }}
                      >
                        <FormattedMessage
                          id="account-strength-meter"
                          defaultMessage="Strength Meter" />:
                      </span>
                      <ReactPasswordStrength
                        className="react-form-element"
                        minLength={8}
                        minScore={2}
                        scoreWords={[
                          "Weak",
                          "Ok",
                          "Good",
                          "Strong",
                          "Stronger",
                        ]}
                        inputProps={{ name: "password", autoComplete: "off" }}
                        changeCallback={handlePasswordChange}
                      />
                      <span style={{ color: "red" }}>{errors.password}</span>
                    </div>
                    <div className="form-element">
                      <label htmlFor="confirmPassword"><FormattedMessage
                        id="account-confirm-password"
                        defaultMessage="Confirm Password" />:</label>
                      <input
                        type="password"
                        name="confirmPassword"
                        value={formValues.confirmPassword}
                        onChange={handleInputChange}
                        style={{
                          borderColor: errors.confirmPassword ? "red" : "",
                        }}
                      />
                      {!!errors.confirmPassword && (
                        <span style={{ color: "red" }}>
                          {errors.confirmPassword}
                        </span>
                      )}
                    </div>
                  </>
                )}

              <div className="form-element">
                <label htmlFor="city">
                  <FormattedMessage
                    id="account-city"
                    defaultMessage="City" />: <span className="input-required">*</span>
                </label>
                <input
                  required
                  onChange={handleInputChange}
                  name="city"
                  type="text"
                  maxLength="50"
                  value={formValues.city}
                />
                <span style={{ color: "red" }}>{errors.city}</span>
              </div>

              <div className="form-element">
                <label htmlFor="state">
                  <FormattedMessage
                    id="account-state"
                    defaultMessage="State" />: <span className="input-required">*</span>
                </label>
                <input
                  required
                  onChange={handleInputChange}
                  name="state"
                  type="text"
                  maxLength="15"
                  value={formValues.state}
                />
                <span style={{ color: "red" }}>{errors.state}</span>
              </div>
            </fieldset>

            {user?.membership?.membershipType && (
              <fieldset>
                <legend><FormattedMessage
                  id="account-membership"
                  defaultMessage="Membership" /></legend>
                <div className="form-element">
                  {getMemberShipMessage(user.membership.membershipType)}
                  <dl>
                    <dt><FormattedMessage
                      id="account-membership-type"
                      defaultMessage="Membership Type" />:</dt>
                    <dd style={{ textTransform: "capitalize" }}>{user.membership.membershipType}</dd>
                    <dt><FormattedMessage
                      id="account-member-since"
                      defaultMessage="Member since" />:</dt>
                    <dd>
                      {/* Firebase timestamp is an object, get the value of 'seconds' and multiply to get milliseconds */}
                      {dayjs(user.membership.createdDate.seconds * 1000).format(
                        "dddd, MMMM D, YYYY"
                      )}
                    </dd>
                    {user.membership?.expiresDate && (
                      <>
                        <dt><FormattedMessage
                          id="account-membership-expiration-date"
                          defaultMessage="Membership expiration date" />:</dt>
                        <dd>
                          {/* Firebase timestamp is an object, get the value of 'seconds' and multiply to get milliseconds */}
                          {dayjs(user.membership.expiresDate.toMillis()).format(
                            "dddd, MMMM D, YYYY"
                          )}
                        </dd>
                      </>
                    )}
                  </dl>
                  {user.membership.membershipType === "individual" && (
                    <p>
                      <Link
                        to={"/cancel-subscription"}
                        className="component-button component-button-default"
                      >
                        <FormattedMessage
                          id="account-disable-account"
                          defaultMessage="Disable account" />
                      </Link>
                    </p>
                  )}
                </div>
              </fieldset>
            )}

            <div className="form-element form-element-submit">
              <button
                className="component-button component-button-submit"
                type="submit"
                style={{ overflowWrap: 'normal' }}
              >
                <FormattedMessage
                  id="account-save"
                  defaultMessage="Save" />
              </button>
            </div>
            {messages.length > 0 && (
              <section className="messages" style={{ width: '100%' }}>
                <Alert variant="success">
                  <ul>
                    {messages.map((message, index) => (
                      <li key={index}>{message}</li>
                    ))}
                  </ul>
                </Alert>
              </section>
            )}
            {errorMessages.length > 0 && (
              <section className="messages" style={{ width: '100%' }}>
                <Alert variant="danger">
                  <ul>
                    {errorMessages.map((message, index) => (
                      <li key={index}>{message}</li>
                    ))}
                  </ul>
                </Alert>
              </section>
            )}
          </form>
          <div className="point-total-list">
            {!!pointEvents ? (
              <>
                <h3 className="legend"><FormattedMessage
                  id="account-points-List"
                  defaultMessage="Points List" /></h3>
                <List className="point-events-list">
                  {pointEvents.map((pointEvent, idx) => (
                    <List.Item className="point-events-list-item" key={idx}>
                      <List.Content>
                        {(pointEvent.pointEventDescription || pointEvent.pointEventDescriptionEs)
                          ? locale === 'es-ES' ? pointEvent.pointEventDescriptionEs : pointEvent.pointEventDescription
                          : locale === 'es-ES' ? pointEvent.messageEs : pointEvent.message}
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
    </section>
  )
}

export default MyAccount
